interface Detail {
    title: string;
    description: string;
    link: string;
}

interface SeoCode {
    [key: string]: Detail;
}

export const MetaDataSeo: SeoCode = {
    // ---------------------> PreHome
    PreHomeVideo: {
        title: 'Santo Vecino, una plataforma de intercambios | La Forma más sencilla de hacer trueques en Latinoamérica',
        description:
            'Santo Vecino es la plataforma de intercambios más grande de México y está revolucionando la forma en que las personas intercambian bienes y servicios. Descubre esta nueva forma de hacer tratos.',
        link: 'https://santovecino.com/prehome/video',
    },
    PreHomeTutorial: {
        title: '',
        description: '',
        link: 'https://santovecino.com/prehome/tutorial',
    },
    // ---------------------> Home
    Home: {
        title: 'Santo Vecino: plataforma de intercambios mexicana | Trueque de bienes y servicios',
        description:
            'Descubre la plataforma de intercambios más grande de México y Latinoamérica: Santo Vecino. Aquí hay trueques de chambas y cosas donde la colaboración es nuestra bandera de resistencia económica.',
        link: 'https://santovecino.com/inicio',
    },
    // ---------------------> Authentication
    Login: {
        title: 'Santo Vecino: plataforma de intercambios y trueque de cosas y chambas | Regístrate gratis',
        description:
            'Santo Vecino es la plataforma definitiva para intercambiar fácilmente en América Latina. Intercambia cosas y talentos. ¡Únete a nosotros ahora y comience a realizar intercambios gratis!',
        link: 'https://santovecino.com/autenticacion/login',
    },
    FaceVerification: {
        title: 'Santo Vecino: plataforma de intercambios mexicana | Trueque de bienes y servicios',
        description:
            'Descubre la plataforma de intercambios más grande de México y Latinoamérica: Santo Vecino. Aquí hay trueques de chambas y cosas donde la colaboración es nuestra bandera de resistencia económica.',
        link: 'https://santovecino.com/autenticacion/completarCuenta/:data',
    },
    ForgotPassword: {
        title: 'Santo Vecino: plataforma de intercambio | Trueque de cosas y chambas',
        description:
            'Santo Vecino es la plataforma de trueques más grande de México y está revolucionando la forma en que las personas intercambian bienes y servicios. Descubre una nueva forma de colaborar a través de la economía colaborativa.',
        link: 'https://santovecino.com/autenticacion/olvideContrasena',
    },
    RecoverPassword: {
        title: '',
        description: '',
        link: 'https://santovecino.com/autenticacion/recuperarContrasena/:data',
    },

    UserSingUp: {
        title: 'Santo Vecino: Plataforma de Intercambio | Trueques Sencillos y Gratuitos en México | Regístrate Gratis y Cambia Cosas Usadas o Busca Trabajo',
        description:
            'Santo Vecino, una nueva plataforma de intercambios en Latinoamérica. Regístrate gratis y descubre la forma más sencilla de hacer trueques de cosas usadas o de chambas. Es momento de cambiar, ¡intercambia ahora!',
        link: 'https://santovecino.com/autenticacion/registrarCuenta',
    },
    VerifyAccount: {
        title: '',
        description: '',
        link: 'https://santovecino.com/autenticacion/verificarCorreo',
    },
    Code: {
        title: '',
        description: '',
        link: 'https://santovecino.com/autenticacion/verificarCodigo',
    },
    // ---------------------> barter
    barter: {
        title: '',
        description: '',
        link: 'https://santovecino.com/barter',
    },
    // ---------------------> chat
    chat: {
        title: '',
        description: '',
        link: 'https://santovecino.com/chat',
    },
    // ---------------------> notificaciones
    Notifications: {
        title: '',
        description: '',
        link: 'https://santovecino.com/notificaciones',
    },
    // ---------------------> Publicacion
    ChooseType: {
        title: 'Santo Vecino: plataforma de intercambios en Latinoamérica | Haz trueques | Regístrate gratis',
        description:
            'Genera un cambio positivo en la sociedad con Santo Vecino, la plataforma de trueques más grande de México. Regístrate gratis y cambia bienes y servicios fácilmente. ¡Llegó la era del trueque!',
        link: 'https://santovecino.com/publicacion/elegir',
    },

    FirstPost: {
        title: 'Santo Vecino: plataforma de intercambios | Trueque de cosas y chambas',
        description:
            'Regístrate gratis en Santo Vecino, la plataforma de intercambios más sencilla en México. Regístrate ahora y descubre una nueva forma de conseguir lo que necesitas.',
        link: 'https://santovecino.com/publicacion/primera',
    },
    OperationHaveIt: {
        title: 'Santo Vecino: plataforma de intercambios | Regístrate gratis y comienza a intercambiar',
        description:
            'Cámbialo todo con Santo Vecino - la plataforma de intercambios más sencilla de Latinoamérica. Regístrate gratis y únete a nuestra comunidad para cambiar cosas o buscar chamba.',
        link: 'https://santovecino.com/publicacion/haveIt',
    },
    OperationWantIt: {
        title: 'Santo Vecino: Plataforma Web de Intercambio en México | Regístrate Gratis para  hacer trueque de Cosas y Chambas',
        description:
            'Regístrate gratis en Santo Vecino, la plataforma de intercambio más sencilla de Latinoamérica. Cambia cosas usadas, busca empleo, haz trueques de bienes y servicios. ¡Es momento de intercambiar y cambiarlo todo!',
        link: 'https://santovecino.com/publicacion/wantIt',
    },
    OperationGiveYouHand: {
        title: 'Regístrate ahora y comienza a realizar intercambios en nuestra comunidad. ¡Cámbialo todo con Santo Vecino!',
        description:
            'Santo Vecino, una plataforma de intercambios muy fácil de usar. Regístrate gratis y comienza a intercambiar tus cosas usadas o a encontrar oportunidades laborales. Es hora de cambiarlo todo.',
        link: 'https://santovecino.com/publicacion/giveYouHand',
    },
    OperationGiveMeHand: {
        title: 'Regístrate en la plataforma y comienza a realizar intercambios de forma fácil y segura. ¡Cámbialo todo con Santo Vecino!',
        description:
            'Santo Vecino - la plataforma de intercambios más sencilla de Latinoamérica. Regístrate gratis y comienza a cambiar cosas usadas, buscar chamba y hacer trueques de bienes y servicios en un instante. ¡Es el momento de intercambiar en Santo Vecino!',
        link: 'https://santovecino.com/publicacion/giveMeHand',
    },
    ColorPost: {
        title: '¡Regístrate ahora mismo en Santo Vecino y cambia tu vida!',
        description:
            'Santo Vecino, plataforma gratuita que te permite hacer trueques de forma sencilla y rápida. Podrás intercambiar cosas y chambas con otras personas, sin necesidad de utilizar dinero.',
        link: 'https://santovecino.com/publicacion/color',
    },
    StructurePost: {
        title: 'Regístrate gratis ahora mismo y descubre cómo hacer trueques de cosas o chambas fácilmente en nuestra plataforma. ¡No esperes más para cambiarlo todo, con Santo Vecino!',
        description:
            'Santo Vecino es la plataforma que simplifica la forma de intercambiar bienes y servicios. Regístrate gratis y comienza a intercambiar cosas hoy.',
        link: 'https://santovecino.com/publicacion/estructura',
    },
    ConfirmPost: {
        title: 'Regístrate ahora mismo y comienza a disfrutar de los beneficios del intercambio. ¡No esperes más!',
        description:
            'Santo Vecino, una plataforma gratuita para intercambiar cosas y servicios. Hacer trueques nunca fue tan sencillo. Puedes intercambiar tus objetos sin gastar dinero y encontrar oportunidades o bien hacer trueque de chambas interesantes.',
        link: 'https://santovecino.com/publicacion/confirmar',
    },
    CreatePublication: {
        title: '',
        description: '',
        link: 'https://santovecino.com/publicacion/crear',
    },
    PreviewPublication: {
        title: '',
        description: '',
        link: 'https://santovecino.com/publicacion/previsualizar',
    },
    PreviewSavedPublication: {
        title: '',
        description: '',
        link: 'https://santovecino.com/publicacion/vista-previa',
    },
    UpdatePublication: {
        title: '',
        description: '',
        link: 'https://santovecino.com/publicacion/actualizar',
    },
    // ---------------------> Privacy
    TermsPolitics: {
        title: 'Santo Vecino, cámbialo todo: plataforma web de intercambios en México | Regístrate gratis y comienza a intercambiar.',
        description:
            'Descubre Santo Vecino, la plataforma web de intercambios mexicana que te permite cambiar de todo. Regístrate gratis y encuentra trueques de cosas y chambas. ¡No esperes más, conócela!',
        link: 'https://santovecino.com/privacy/policy',
    },
    CookiesUser: {
        title: '',
        description: '',
        link: 'https://santovecino.com/privacy/cookies',
    },
    // ---------------------> Profile
    UserSettings: {
        title: 'Santo Vecino, plataforma de trueques en México, totalmente gratuita.',
        description:
            'Intercambia cosas y chambas fácilmente. Regístrate gratis y realiza trueques de forma sencilla con Santo Vecino.',
        link: 'https://santovecino.com/profile/settings',
    },
    Security: {
        title: 'Intercambia cosas fácilmente con Santo Vecino.',
        description:
            'Plataforma gratuita para hacer intercambios de cosas y chambas. Realiza trueques de manera sencilla y rápida.',
        link: 'https://santovecino.com/profile/security',
    },
    AcknowledgmentsProfile: {
        title: 'Intercambia cosas y chambas en Santo Vecino.',
        description:
            'Plataforma gratuita para hacer trueques. Regístrate y realiza intercambios de manera sencilla con Santo Vecino.',
        link: 'https://santovecino.com/profile/acknowledgments',
    },
    Followers: {
        title: 'Nunca fue tan sencillo hacer trueques y obtener lo que deseas sin gastar dinero, empieza a intercambiar con Santo Vecino.',
        description:
            'Hacer trueques nunca había sido tan fácil. Conoce Santo Vecino, una plataforma donde ahorras dinero mientras obtienes lo que necesitas. ¡Imagina todas las posibilidades con Santo Vecino!',
        link: 'https://santovecino.com/profile/followers',
    },
    UpdatePassword: {
        title: '',
        description: '',
        link: 'https://santovecino.com/profile/password',
    },
    Profile: {
        title: 'Realiza intercambios de manera rápida y segura con Santo Vecino. Regístrate gratis y comienza a cambiar todo lo que quieras.',
        description:
            'Santo Vecino es una plataforma de intercambios gratuita que te permite cambiar cosas y servicios de manera sencilla. Ya no tendrás que preocuparte por el dinero, simplemente encuentra a alguien dispuesto a hacer un trueque contigo.',
        link: 'https://santovecino.com/profile',
    },
    // ---------------------> Categorías
    Category: {
        title: 'Santo Vecino: Plataforma de Intercambio | Trueque de cosas en Latinoamérica',
        description:
            'Regístrate gratis en Santo Vecino, la plataforma de intercambio que cambia todo. Descubre la forma más sencilla de hacer trueques en Latinoamérica, intercambiando bienes, servicios y hasta buscar chamba. ¡Es momento de intercambiar cosas usadas y cambiar tu vida!',
        link: 'https://santovecino.com/categorias',
    },
    SubCategory: {
        title: '',
        description: '',
        link: 'https://santovecino.com/categoria/:id/subcategoria/:idSubcategory',
    },
    ResultSearch: {
        title: '',
        description: '',
        link: 'https://santovecino.com/resultados/:search',
    },

    // ---------------------> Fuera de region
    PageIpInvalid: {
        title: '',
        description: '',
        link: 'https://santovecino.com/fuera_de_region',
    },
    // ---------------------> Mantenimiento
    Offline: {
        title: '',
        description: '',
        link: 'https://santovecino.com/mantenimiento',
    },
    // ---------------------> Pagina no encontrada
    PageNotFound: {
        title: '',
        description: '',
        link: 'https://santovecino.com/?',
    },
};

/*
: {
    title: '',
    description: '',
    link: '',
},

*/
